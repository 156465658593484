<template>
    <v-container>
        <ASuccessFour :api="api"/>
        <div class="d-flex justify-start">
            <v-btn
            @click="redirectBack"
            class="mb-3"
            color="primary">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
            
                    </v-skeleton-loader>
                    <v-data-table
                    show-select="false"
                v-model="selectedCompanies"
                    item-key="company_id"
                    :search="companiesSearch"
                    :items="companies"
                    :headers="this.companiesHeader"
                    class="elevation-1"
                    dense v-if="this.companies!=null && !api.isLoading" >
            
                    <template v-slot:top>
                        <v-toolbar flat height="auto">
                            <v-row no-gutters>
                                <v-col>
                                    <v-row class="ma-3">
            
                                        <v-toolbar-title class="font-weight-bold text-h4">
                                            Companies
                                        </v-toolbar-title>
                                    </v-row>
                                    <v-row class="mt-5">
            
                                        <v-text-field class="mx-4" dense outlined label="Search"
                                        v-model="companiesSearch">
            
                                        </v-text-field>
                                    </v-row>
                                    <v-row class="mx-4 my-4">
                                            <div v-if="totalCompanies!=null">
                                                <span class="text-h7 grey--text">
                                                    Total  Companies Found : {{ totalCompanies }}

                                                </span>
                                            </div>
                                        </v-row>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                    </template>
            
            
                    <template v-slot:item.index="{item}">
                        {{ companies.indexOf(item)+1 }}
                    </template>
            
            
                    </v-data-table>
                    
        
                </div>

            </v-col>
            <v-col>
                <div class="px-3">
                    <div v-if="this.selectedCompanies!=null && Object.keys(this.selectedCompanies).length!=0" >
                        <v-card>
                            <v-toolbar color="secondary white--text text-h6">
                                <v-row no-gutters class="ma-3">
                                    <v-col>
                                        <v-row>
                                            <v-toolbar-title>
                                                Selected companies
                                            </v-toolbar-title>
                                            <v-spacer>
            
                                            </v-spacer>
                                            <v-btn 
                                            @click="submit" color="primary">
                                                Confirm
                                            </v-btn>
                                        </v-row>
                                     
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <div class="pt-3">
                                <div v-for="(company,index) in this.selectedCompanies"
                                :key="index"
                                >
                                    <v-list-item dense outlined elevation-1>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ (index+1)+" . " }} {{ company.company_name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </div>
                        </v-card>
                    </div>
                    <div v-else class="mx-2">
                        <v-card>
                            <v-toolbar color="secondary white--text text-h6">
                                <v-row no-gutters class="ma-3">
                                    <v-col>
                                        <v-row>
                                            <v-toolbar-title>
                                                Selected companies
                                            </v-toolbar-title>
                                        </v-row>
            
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <div class="pt-3">
                                <span class="text-h7 ma-3 grey--text">
                                    No Company Selected
                                </span>
                            </div>
                        </v-card>
                    </div>
                </div>

            </v-col>
        </v-row>

        <!-- <div v-if="choosePIC">
            <PagePICCompaniesChoose :companies="this.selectedCompanies" />
        </div> -->
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
        ASuccessFour,
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    choosePIC:false,
    selectedCompanies:null,
    companiesSearch:null,
    companies:null,
    companiesHeader:[
        {
            text:'#',
            value:'index',
            align:'start',
        },
        {
            text:'Company Name',
            value:'company_name',
        },
        {
            text:'Plan',
            value:'plan_name',
        },
        {
            text:'Subsription Status',
            value:'verification',
        },
        {
            text:'Subscribed Date',
            value:'created_at',
        }
    ],
    totalCompanies:null,
    
    api:{
        isSuccesful:false,
        isLoading:false,
        isError:false,
        error:null,
        success:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getCompaniesClientActive"){
                this.companies = resp.data.companies;
                this.totalCompanies = resp.data.total;
            }
            if(resp.class=="addPICMultipleCompanies"){
                this.api.isSuccesful = true;
                this.api.success = "Sucesfully Assign The Companies";
            }
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesApi = this.fetchCompanies();
            this.$api.fetch(fetchCompaniesApi);
        },
        fetchCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/subscription/Active?unassigned=true&userId="+this.$router.history.current.params.id;
            return tempApi;
        },
        submit(){
            let AssignNewCompaniesApi = this.assignNewCompanies();
            this.$api.fetch(AssignNewCompaniesApi);
        },
        assignNewCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic/multiple/"+this.$router.history.current.params.id;
            let formData = new FormData;
            this.selectedCompanies.forEach(companies => {
                formData.append('companyId[]',companies.company_id);
            });
            tempApi.params = formData;
            return tempApi;
        
        },     
        redirectBack(){
            this.$router.go(-1);
        },
    
    }
}
</script>